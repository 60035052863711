<template>
    <div>
        <!-- step1 -->
        <div v-if="form.order_type == 1">
            <div class="form-group mb-3">
                <label for="">Survey အတွက် လူကြီးမင်းခေါ်ယူလိုသော နေ့ရက်နှင့် အချိန်အားရွေးချယ်ပါရှင်။</label>
                <functional-calendar
                    v-model="survey_date"
                    :isDatePicker='true'  
                    :changeMonthFunction="changeMonth"
                    :withTimePicker	='true'
                    v-bind:class="{'is-invalid' : validation.survey_date}"
                    @choseDay="surveyDateChange"
                >
                </functional-calendar>
                <div class="invalid-feedback">Survey Date is required!</div>
            </div>
        </div>

        <div v-else>

            <div class="form-group mb-3">
            <label for="">ခေါ်ယူလိုသော နေ့ရက်အားရွေးချယ်ပါရှင်။</label>
            <functional-calendar
                v-model="dates"
                :is-multiple-date-picker='is_multiple'
                :isDatePicker='is_date'
                :changeMonthFunction="changeMonth"
                :limits="dateLimit"
                v-bind:class="{'is-invalid' : validation.date}"
                @choseDay="dateChange"
            >
            </functional-calendar>
            <div class="invalid-feedback"> Date ရွေးပေးရန်လိုသည်။</div>
            </div>

            <div class="form-group mb-3">
            <label for="">ဝန်ဆောင်မှုစတင်လိုသောအချိန်အားရွေးပေးပါရှင်။</label>
            <select 
                v-bind:class="{'form-control form-select form-select-lg mb-3':true, 'is-invalid' : validation.start_time}"
                aria-label=".form-select-lg" 
                v-model="form.start_time">
                <option v-for="(start_time, index) in start_times" :key="index" :value="start_time.value"> {{ start_time.name_mm }} </option>
            </select>
            <div class="invalid-feedback"> Start Time is required!</div>
            </div>

            <div class="form-group mb-3" v-show="condition_is_duration === 1">
                <label for="">ဘယ်နှနာရီစာ order တင်မည်ဆိုတာကို ရွေးချယ်ပေးပါရှင်။</label>
                <select 
                v-bind:class="{'form-control form-select form-select-lg mb-3':true, 'is-invalid' : validation.duration}"
                aria-label=".form-select-lg" 
                v-model="form.duration"
                v-on:change="validation.duration = false">
                >
                <option v-for="(duration, index) in durations" :key="index" :value="duration.duration"> {{ duration.name }} {{ duration.price }} </option>
                </select>
                <div class="invalid-feedback"> Duration is required!</div>
            </div>

            <div v-show="condition_is_square_feet === 1">
                <div class="form-group mb-3" v-show="condition_square_feet_type === 1">
                <label for="">နေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။</label>
                <input type="number" 
                    v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.sqft}"
                    v-on:keyup="!form.sqft ? validation.sqft = true : validation.sqft = false"
                    v-model="form.sqft" @input="getAdditionalItems" 
                    placeholder="နေရာအကျယ်အဝန်း(sqft)">
                <div class="invalid-feedback"> Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                </div>

                <div class="form-group mb-3" v-show="condition_square_feet_type === 2">
                <label for="">အိမ်တွင်းနေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။</label>
                <input type="number" 
                    class="form-control form-control-lg" 
                    v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.indoor_sqft}"
                    v-on:keyup="!form.indoor_sqft ? validation.indoor_sqft = true : validation.indoor_sqft = false"
                    v-model="form.indoor_sqft" @input="getAdditionalItems" 
                    placeholder="အိမ်တွင်းနေရာအကျယ်အဝန်း(sqft)">
                <div class="invalid-feedback"> Indoor Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                </div>

                <div class="form-group mb-3" v-show="condition_square_feet_type === 2">
                <label for="">အိမ်ပြင်နေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။</label>
                <input type="number" 
                v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.outdoor_sqft}"
                v-on:keyup="!form.outdoor_sqft ? validation.outdoor_sqft = true : validation.outdoor_sqft = false"
                v-model="form.outdoor_sqft" @input="getAdditionalItems" 
                placeholder="အိမ်ပြင်နေရာအကျယ်အဝန်း(sqft)">
                <div class="invalid-feedback"> Outdoor Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                </div> 
            </div>

            <!-- additional items -->
            <div class="mb-3" v-show="is_additional_item">
                <div class="form-group mb-3" v-for="(item, index) in additional_items" :key="index">
                    <label for=""> {{ item.name }} </label>
                    <input type="text" class="form-control form-control-lg" v-model="form.additional_values[item.id]" :key="item.id">
                </div>  
            </div>

            <div class="form-group mb-3" v-show="is_addon_item">
                <label for="">ထပ်ဆောင်းအနေဖြင့် ဘာများယူပါဦးမလဲရှင်။ ရွေးချယ်မှုအလိုက် ထပ်ဆောင်းကျသင့်မည်ဖြစ်သည်။ </label>
                <div class="form-check mb-1" v-for="(addon_item, index) in addon_items" :key="index">
                <input class="form-check-input" type="checkbox" v-model="form.addon_items[addon_item.id]" :id="addon_item.id"  :value="addon_item.id">
                <label class="form-check-label" :for="addon_item.id">
                    {{ addon_item.name }}
                </label>
                </div>
            </div>
        </div>

        <div class="col-12">
            <button type="button" class="btn btn-primary btn-lg btn-block" @click.prevent="next()">ရှေ့ဆက်မည်။</button>
        </div>
    </div>


<!-- // <div class="form-group mb-3">
//                 <label for="">ဝန်ဆောင်မှုစတင်လိုသောအချိန်အားရွေးပေးပါရှင်။</label>
//                 <select 
//                     v-bind:class="{'form-control form-select form-select-lg mb-3':true, 'is-invalid' : validation.start_time}"
//                     aria-label=".form-select-lg" 
//                     v-model="form.start_time">
//                     <option v-for="(start_time, index) in start_times" :key="index" :value="start_time.value"> {{ start_time.name_mm }} </option>
//                 </select>
//                 <div class="invalid-feedback"> Start Time is required!</div>
//               </div>

// <div class="form-group mb-3" v-show="condition_is_duration === 1">
// <label for="">ဘယ်နှနာရီစာ order တင်မည်ဆိုတာကို ရွေးချယ်ပေးပါရှင်။</label>
// <select 
// v-bind:class="{'form-control form-select form-select-lg mb-3':true, 'is-invalid' : validation.duration}"
// aria-label=".form-select-lg" 
// v-model="form.duration"
// v-on:change="validation.duration = false">
// >
// <option v-for="(duration, index) in durations" :key="index" :value="duration.duration"> {{ duration.name }} {{ duration.price }} </option>
// </select>
// <div class="invalid-feedback"> Duration is required!</div>
// </div>

// <div v-show="condition_is_square_feet === 1">
// <div class="form-group mb-3" v-show="condition_square_feet_type === 1">
// <label for="">နေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။</label>
// <input type="number" 
//     v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.sqft}"
//     v-on:keyup="!form.sqft ? validation.sqft = true : validation.sqft = false"
//     v-model="form.sqft" @input="getAdditionalItems" 
//     placeholder="နေရာအကျယ်အဝန်း(sqft)">
// <div class="invalid-feedback"> Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
// </div>

// <div class="form-group mb-3" v-show="condition_square_feet_type === 2">
// <label for="">အိမ်တွင်းနေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။</label>
// <input type="number" 
//     class="form-control form-control-lg" 
//     v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.indoor_sqft}"
//     v-on:keyup="!form.indoor_sqft ? validation.indoor_sqft = true : validation.indoor_sqft = false"
//     v-model="form.indoor_sqft" @input="getAdditionalItems" 
//     placeholder="အိမ်တွင်းနေရာအကျယ်အဝန်း(sqft)">
// <div class="invalid-feedback"> Indoor Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
// </div>

// <div class="form-group mb-3" v-show="condition_square_feet_type === 2">
// <label for="">အိမ်ပြင်နေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။</label>
// <input type="number" 
// v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.outdoor_sqft}"
// v-on:keyup="!form.outdoor_sqft ? validation.outdoor_sqft = true : validation.outdoor_sqft = false"
// v-model="form.outdoor_sqft" @input="getAdditionalItems" 
// placeholder="အိမ်ပြင်နေရာအကျယ်အဝန်း(sqft)">
// <div class="invalid-feedback"> Outdoor Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
// </div> 
// </div>

// <div class="mb-3" v-show="is_additional_item">
// <div class="form-group mb-3" v-for="(item, index) in additional_items" :key="index">
//     <label for=""> {{ item.name }} </label>
//     <input type="text" class="form-control form-control-lg" v-model="form.additional_values[item.id]" :key="item.id">
// </div>  
// </div>

// <div class="form-group mb-3" v-show="is_addon_item">
// <label for="">ထပ်ဆောင်းအနေဖြင့် ဘာများယူပါဦးမလဲရှင်။ ရွေးချယ်မှုအလိုက် ထပ်ဆောင်းကျသင့်မည်ဖြစ်သည်။ </label>
// <div class="form-check mb-1" v-for="(addon_item, index) in addon_items" :key="index">
// <input class="form-check-input" type="checkbox" v-model="form.addon_items[addon_item.id]" :id="addon_item.id"  :value="addon_item.id">
// <label class="form-check-label" :for="addon_item.id">
//     {{ addon_item.name }}
// </label>
// </div>
// </div> -->


</template>
<script>
	export default {
		props: ['form', 'currentRoom'],

		data: function() {
			return {
				selected: ''
			}
		},

		created() {
			this.selected = this.currentRoom;
		}
	}
</script>
