/* eslint-disable */
import axios from 'axios';

let test_url = 'http://127.0.0.1:8000/api/chat-bot-form';
let url = "https://sys.myanants.com/api/chat-bot-form";
let stg_url = "http://stg.myanants.com/api/chat-bot-form";
const apiClient = axios.create({
    baseURL: url,
    // withCredentials: false,
    // headers: {
    //     Accept: 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json'
    // }
})

export default {
    
    getServiceDetail(id) {
        return apiClient.get(`/detail/${id}`);
    },

    getTownships() {
        return apiClient.get("/townships")
    },

    getDurations(id,service_date,service_type,maid_qty) {
        return apiClient.get("/duration", {
            params: {
                "main_service_id": id,
                "service_date" : service_date,
                "service_type" : service_type,
                "maid_qty": maid_qty,
            }
        });
    },

    getStartTimes(id) {
        return apiClient.get(`/start_time/${id}`);
    },

    checkPhone(phone) {
        return apiClient.get(`/check-phone/${phone}`);
    },

    getCustomerAddresses(customer_id) {
        return apiClient.get(`/customer-address/${customer_id}`);
    },

    getAdditionalItems(id, sqft, indoor_sqft, outdoor_sqft) {
        return apiClient.get("/additional-items", {
            params: {
                "main_service_id": id,
                "sqft" : sqft,
                "indoor_sqft" : indoor_sqft,
                "outdoor_sqft": outdoor_sqft,
            }
        });
    },

    getAddOnItems(id, duration) {
        duration = duration ?? 3;
        return apiClient.get(`/addon-services/${id}/${duration}`);  
    },
    
    makeOrder(data, dates) {
        return apiClient.get('/order', {
            params: {
                "form": JSON.stringify(data),
                "service_date" : JSON.stringify(dates),
            }
        });
    },

    getFields(user_id) {
        return apiClient.get(`/getFields/${user_id}`);
    },

    checkDate(date, id) {
        return apiClient.get(`/check-date`, {
            params: {
                "date" : JSON.stringify(date),
                'id': id,
            }
        });
    }
}
