<template>
  <div>
    <div class="order_form">
      <b-form @submit="onSubmit">
        <!-- step1 -->
        <div class="step1" v-show="step === 1">
          <!-- step1 -->
          <div v-if="form.order_type == 1">
              <div class="card mb-3">
                <div class="card-header">
                    Survey အတွက် လူကြီးမင်းခေါ်ယူလိုသော နေ့ရက်နှင့် အချိန်အားရွေးချယ်ပါရှင်။
                </div>
                <div class="card-body">
                    <div v-show="is_default_survey">
                      <div class="custom-control custom-radio mb-3">
                          <input type="radio" v-model="form.is_default_survey" 
                          class="custom-check-input"
                          :value="1" 
                          :id="form.survey_date"
                          @change="changeSurveyDate">
                          <label class="custom-check-label" :for="form.survey_date"> {{ form.survey_date }} </label>
                      </div>
                      <div class="custom-control custom-radio mb-3">
                          <input type="radio" 
                          v-model="form.is_default_survey" 
                          class="custom-check-input"
                          id="otherOption" value="0" 
                          @change="changeSurveyDate">
                          <label class="custom-check-label" for="otherOption"> နေ့ရက်နှင့် အချိန်အား ပြန်ရွေးချယ်မည်။ </label>
                      </div>
                    </div>
                    <div v-show="is_survey_date">
                      <functional-calendar
                          v-model="survey_date"
                          :isDatePicker='true'  
                          :changeMonthFunction="changeMonth"
                          :withTimePicker	='true'
                          v-bind:class="{'is-invalid' : validation.survey_date}"
                          @choseDay="surveyDateChange"
                      >
                      </functional-calendar>
                    </div>
                    <div class="invalid-feedback">Survey Date is required!</div>
                </div>
              </div>
          </div>

          <div v-else>
              <div class="card text-white bg-danger mb-3" v-show="condition_is_transportation_charge === 1">
                <div class="card-body">
                  လက်ရှိသွားရေးလာရေးခက်ခဲမှုအခြေအနေများကြောင့် အကူများအတွက် ကားခ {{this.condition_transportation_charge}} ကျပ် ပိုမိုကောက်ခံမည်ဖြစ်ကြောင်းအသိပေးအပ်ပါသည်။
                </div>
              </div>
              <div v-if="this.showDate">
                <div class="card mb-3">
                  <div class="card-header">
                    ခေါ်ယူလိုသော နေ့ရက်အားရွေးချယ်ပါရှင်။
                  </div>
                  <div class="card-body">
                    <functional-calendar
                        v-model="dates"
                        :is-multiple-date-picker='is_multiple'
                        :isDatePicker='is_date'
                        :changeMonthFunction="changeMonth"
                        :limits="dateLimit"
                        v-bind:class="{'is-invalid' : validation.date}"
                        @choseDay="dateChange"
                    >
                    </functional-calendar>
                    <div class="invalid-feedback"> {{ this.validation.date_msg }} </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-show="form.is_urgent">
                <div class="card text-white bg-info mb-3">
                    <div class="card-body">
                      ရွေးချယ်လိုက်သော Order သည် Urgent Order ဖြစ်ပါသည်။
                    </div>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-header">
                  ဝန်ဆောင်မှုစတင်လိုသောအချိန်အားရွေးပေးပါရှင်။
                </div>
                <div class="card-body">
                  <select 
                      v-bind:class="{'form-control form-select form-select-lg mb-3':true, 'is-invalid' : validation.start_time}"
                      aria-label=".form-select-lg" 
                      v-model="form.start_time">
                      <option v-for="(start_time, index) in start_times" :key="index" :value="start_time.value"> {{ start_time.name_mm }} </option>
                  </select>
                  <div class="invalid-feedback"> စတင်လိုသောအချိန်အားရွေးပေးရန်လိုသည်။ </div>
                </div>
              </div>

              <div class="card mb-3" v-show="condition_is_duration === 1">
                <div class="card-header">
                  ဘယ်နှနာရီစာ order တင်မည်ဆိုတာကို ရွေးချယ်ပေးပါရှင်။
                </div>
                <div class="card-body">
                  <select 
                    v-bind:class="{'form-control form-select form-select-lg mb-3':true, 'is-invalid' : validation.duration}"
                    aria-label=".form-select-lg" 
                    v-model="form.duration"
                    v-on:change ="changeDuration">
                    >
                      <option v-for="(duration, index) in durations" :key="index" :value="duration.duration"> {{ duration.name }} {{ duration.price }} </option>
                    </select>
                    <div class="invalid-feedback"> Duration is required!</div>
                </div>
              </div>

              <div v-show="condition_is_square_feet === 1">
                <div class="card mb-3" v-show="condition_square_feet_type <= 1">
                  <div class="card-header">
                    နေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။
                  </div>
                  <div class="card-body">
                    <input type="number" 
                        v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.sqft}"
                        v-on:keyup="!form.sqft ? validation.sqft = true : validation.sqft = false"
                        v-model="form.sqft" @input="getAdditionalItems" 
                        placeholder="နေရာအကျယ်အဝန်း(sqft)">
                    <div class="invalid-feedback"> နေရာအကျယ်အဝန်း ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                  </div>
                </div>

                <div class="card mb-3" v-show="condition_square_feet_type === 2">
                  <div class="card-header">
                    အိမ်တွင်းနေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။
                  </div>
                  <div class="card-body">
                    <input type="number" 
                      class="form-control form-control-lg" 
                      v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.indoor_sqft}"
                      v-on:keyup="!form.indoor_sqft ? validation.indoor_sqft = true : validation.indoor_sqft = false"
                      v-model="form.indoor_sqft" @input="getAdditionalItems" 
                      placeholder="အိမ်တွင်းနေရာအကျယ်အဝန်း(sqft)">
                    <div class="invalid-feedback"> Indoor Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                  </div>
                </div>

                <div class="card mb-3" v-show="condition_square_feet_type === 2">
                  <div class="card-header">
                    အိမ်ပြင်နေရာအကျယ်အဝန်း(sqft)ကို ရိုက်ထည့်ပေးပါရှင်။
                  </div>
                  <div class="card-body">
                    <input type="number" 
                    v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.outdoor_sqft}"
                    v-on:keyup="!form.outdoor_sqft ? validation.outdoor_sqft = true : validation.outdoor_sqft = false"
                    v-model="form.outdoor_sqft" @input="getAdditionalItems" 
                    placeholder="အိမ်ပြင်နေရာအကျယ်အဝန်း(sqft)">
                    <div class="invalid-feedback"> Outdoor Sqft ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                  </div>
                </div>
              </div>

              <div class="mb-3" v-show="is_additional_item">
                <div class="card mb-3" v-for="(item, index) in additional_items" :key="index">
                  <div class="card-header">
                    {{ item.name }}
                  </div>
                  <div class="card-body">
                    <input type="number" 
                    v-bind:class="{'form-control form-control-lg':true, 'is-invalid': validation.additional_items}"
                    v-model="form.additional_values[item.id]" :key="item.id">
                    <div class="invalid-feedback"> {{ item.validation_name }} အတွက် ရိုက်ထည့်ပေးရန်လိုသည်။ </div>
                  </div>
                </div>
              </div>

              <div class="card mb-3" v-show="is_addon_item">
                <div class="card-header">
                  ထပ်ဆောင်းအနေဖြင့် ဘာများယူပါဦးမလဲရှင်။ ရွေးချယ်မှုအလိုက် ထပ်ဆောင်းကျသင့်မည်ဖြစ်သည်။ 
                </div>
                <div class="card-body">
                  <div class="form-check mb-1" v-for="(addon_item, index) in addon_items" :key="index">
                    <input class="form-check-input" type="checkbox" v-model="form.addon_items[addon_item.id]" :id="addon_item.id" :value="addon_item.id">
                    <label class="form-check-label" :for="addon_item.id">
                        {{ addon_item.name }} {{ addon_item.amount != 0 ? `( ${addon_item.amount} ကျပ်)` : '' }}
                    </label>
                  </div>
                </div>
              </div>
          </div>
          <div class="col-12">
              <button type="button" class="btn btn-primary btn-lg btn-block" @click.prevent="next()">ရှေ့ဆက်မည်။</button>
          </div>
        </div>

        <!-- Step2 -->
        <div class="step2" v-show="step === 2">

            <div class="card mb-3">
              <div class="card-header">
                လူကြီးမင်း၏ဖုန်းနံပါတ်အား ရိုက်ထည့်ပေးပါရှင်။
              </div>
              <div class="card-body">
                <input type="number" 
                  v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.phone_no}"
                  v-model="form.phone_no" @keyup="checkPhone" 
                  placeholder="09XXXXXXXXX">
                <div class="invalid-feedback"> ဖုန်းနံပါတ် ထည့်ပေးရန်လိုအပ်သည်။ </div>
              </div>
            </div>

            <div class="mb-3" v-show="is_customer == 1">
                <div class="card text-white bg-info mb-3">
                    <div class="card-body">
                      မြန်အံ့ဝန်ဆောင်မှုအား နာမည် {{ this.form.name }} ဖြင့် registerလုပ်ထားပါသည်။ {{ this.form.name }} အမည်နှင့်orderတင်လိုက်ပါမယ်ရှင့်။ 
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                      ဝန်ဆောင်မှုပေးရမည့် လိပ်စာအား သိပါရစေရှင်။
                    </div>
                    <div class="card-body">
                        <div class="custom-control custom-radio mb-3" v-for="(address, index) in addresses" :key="index">
                            <input type="radio" v-model="form.address_id" 
                            v-bind:class="{'custom-check-input':true, 'is-invalid' : validation.address_id}"
                            :id="address.id" :value="address.id" 
                            @change="customerAddress">
                            <label class="custom-check-label" :for="address.id"> {{ address.name }} </label>
                        </div>
                        <div class="custom-control custom-radio mb-3">
                            <input type="radio" 
                            v-model="form.address_id" 
                            v-bind:class="{'custom-check-input':true, 'is-invalid' : validation.address_id}"
                            id="otherOption" value="0" 
                            @change="customerAddress">
                            <label class="custom-check-label" for="otherOption"> တခြား </label>
                        </div>
                        <div class="invalid-feedback"> လိပ်စာ ရွေးပေးရန်လိုအပ်သည်။ </div>
                    </div>
                </div>
            </div>

            <div class="card mb-3" v-show="is_customer == 0">
                <div class="card-header">
                  လူကြီးမင်း၏အမည်အား သိပါရစေရှင်။
                </div>
                <div class="card-body">
                  <input type="text" 
                  v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.name}"
                  v-on:keyup="!form.name ? validation.name = true : validation.name = false"
                  v-model="form.name" placeholder="အမည်">

                  <div class="invalid-feedback"> အမည် ထည့်ပေးရန်လိုအပ်သည်။ </div>
                </div>
            </div>

            <div v-show="is_address === 1">
                <div class="card mb-3">
                    <div class="card-header">
                        ဝန်ဆောင်မှုပေးရမည့် လိပ်စာအား သိပါရစေရှင်။
                    </div>
                    <div class="card-body">
                        <textarea 
                        v-bind:class="{'form-control':true, 'is-invalid' : validation.address}"
                        v-on:keyup="!form.address ? validation.address = true : validation.address = false"
                        v-model="form.address" 
                        id="" rows="2" placeholder="လိပ်စာ"></textarea>
                        <div class="invalid-feedback"> လိပ်စာ ထည့်ပေးရန်လိုအပ်သည်။ </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        ဝန်ဆောင်မှုပေးရမည့်မြို့နယ်ကို ရွေးချယ်ပေးပါရှင်။
                    </div>
                    <div class="card-body">
                        <select 
                        v-bind:class="{'form-select form-select-lg mb-3':true, 'is-invalid' : validation.township}"
                        aria-label=".form-select-lg" v-model="form.township" 
                        v-on:change="getTownshipName()">
                            <option v-for="(township, index) in townships" :key="index" :value="township.id"> {{ township.name }} </option>
                        </select>
                        <div class="invalid-feedback"> ဝန်ဆောင်မှုပေးရမည့်မြို့နယ်ကို  ထည့်ပေးရန်လိုအပ်သည်။ </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-header">
                        အနီးဆုံး ကားမှတ်တိုင် အမည်ကို သိပါရစေရှင်။
                    </div>
                    <div class="card-body">
                        <input type="text" 
                        v-bind:class="{'form-control form-control-lg':true, 'is-invalid' : validation.bus_stop}"
                        v-on:keyup="!form.bus_stop ? validation.bus_stop = true : validation.bus_stop = false"
                        v-model="form.bus_stop" placeholder="ကားမှတ်တိုင်">
                        <div class="invalid-feedback"> ကားမှတ်တိုင်အမည် ထည့်ပေးရန်လိုအပ်သည်။ </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6">
                    <button type="button" class="btn btn-success btn-lg btn-block " @click.prevent="pre()">နောက်သို့</button>
                </div>
                <div class="col-6">
                    <button type="button" class="btn btn-primary btn-lg btn-block " @click.prevent="next()">ရှေ့ဆက်မည်။</button>
                </div>
            </div>
        </div>

        <!-- Step3 -->
        <div class="step3" v-show="step === 3">
            <div class="card mb-3">
                <div class="card-header">
                    အထူးမှာကြားလိုသော အချက်များရှိပါသလားရှင်။
                </div>
                <div class="card-body">
                    <textarea class="form-control" v-model="form.remark" id="" rows="2" placeholder="မှာကြားချက်"></textarea>
                </div>
            </div>

            <div class="row">
                <div class="col-5">
                    <button type="button" class="btn btn-success btn-lg btn-block " @click.prevent="pre()">နောက်သို့</button>
                </div>
                <div class="col-7">
                    <button type="submit" class="btn btn-primary btn-lg btn-block">Order တင်မည်။</button>
                </div>
            </div>
        </div>

      </b-form>
    </div>
  </div>
</template>

<style scoped>

label {
  font-size: 20px;
  margin-bottom: 8px;
  color: #071402;
}

button {
  width: 98% !important;
  justify-content: center !important;
}

.order_form {
  padding: 10px;
}

</style>
<script>
import Step1 from './Step1.vue'
import Step2 from './Step2.vue'
import Step3 from './Step3.vue'

/* eslint-disable */
import EventService from '@/services/EventServices.js'
import { FunctionalCalendar } from "vue-functional-calendar";

  export default {
    components: {
        FunctionalCalendar,
        Step1,
        Step2,
        Step3
    },
    data() {  
      return {
        step: 1,
        form: {
          is_default_survey: 1,
          start_time: '',
          duration: 3,
          sqft: '',
          indoor_sqft: '',
          outdoor_sqft: '',
          additional_values: {},
          addon_items: {},
          survey_date: '',
          survey_date_name: '',
          survey_time: '',
          phone_no: '',
          name: '',
          township: '',
          township_name: '',
          remark: '',
          address: '',
          address_id: '',
          bus_stop: '',
          scriber_id: '',
          customer_id: '',
          category_type: 1,
          order_type: 0,
          is_urgent: 0,
          date: '',
        },
        is_survey_date: 1,
        is_default_survey: 0,
        step1_validate: false,
        step2_validate: false,
        validation: {
          date: false,
          date_msg: "Date ရွေးပေးရန်လိုသည်။",
          survey_date: false,
          start_time: false,
          duration: false,
          sqft: false,
          indoor_sqft: false,
          outdoor_sqft: false,
          additional_items: false,

          //Step 2
          phone_no: false,
          name: false,
          address: false,
          township: false,
          bus_stop: false,
        },
        showDate: true,
        service_dates: {},
        dates: {},
        survey_date: {},
        is_multiple: false,
        is_date: true,
        changeMonth: true,
        dateLimit: false,
        minDay: 2,
        is_additional_item: 0,
        condition_is_transportation_charge: 0,
        condition_transportation_charge: 0,
        condition_is_default_until: 0,
        condition_is_duration: 0,
        condition_is_maid_quantity: '',
        condition_is_ot: '',
        condition_is_urgent: 0,
        condition_is_package: '',
        condition_is_square_feet: 0,
        condition_square_feet_type: '',
        condition_service_time_type: '',
        is_customer: 0,
        is_address: 1,
        //
        id: 15,
        maid_qty: 1,
        service_date: '',
        until: 1,
        townships: {},
        addresses: {},
        start_times: {},
        durations: {},
        is_addon_item: 0,
        addon_items: {},
        additional_items: {},
        additional_result: {},
      }
    },
    methods: {
      onSubmit(event) {
        event.preventDefault();
        EventService.makeOrder(this.form, this.service_dates).then(data => {
            // the Messenger Extensions JS SDK is done loading 
            MessengerExtensions.requestCloseBrowser(function success() {
              console.log("close");
            }, function error(err) {
              console.log('There is error.', err);
            });

            window.location.href = 'https://sys.myanants.com/api/chat-bot-form/make-order';

        });
        
      },

      pre() {
        if (this.step == 1) {
          this.offStep1Validation();
        }else {
          this.offStep2Validation();
        }
        this.step--;
      },

      offStep1Validation() {
        this.validation.date = false;
        this.validation.date_msg = "Date ရွေးပေးရန်လိုသည်။";
        this.validation.survey_date = false;
        this.validation.start_time = false;
        this.validation.duration = false;
        this.validation.sqft = false;
        this.validation.indoor_sqft = false;
        this.validation.outdoor_sqft = false;
        this.validation.additional_items = false;
      },

      offStep2Validation() {
        this.validation.phone_no = false;
        this.validation.name = false;
        this.validation.address = false;
        this.validation.township = false;
        this.validation.bus_stop = false;
        this.validation.address_id = false;
      },

      next() {
        if (this.step == 1) {
          this.step1_validate = true
          if (this.form.order_type == 1) {
              if (!this.form.survey_date) {
                this.validation.survey_date = true;
                this.step1_validate = false;
              }else {
                this.getSurveyDate();
              }
          }else {
            if (!this.form.start_time) {
              this.validation.start_time = true;
              this.step1_validate = false;
            }
            if (this.condition_is_duration == 1 && !this.form.duration) {
              this.validation.duration = true;
              this.step1_validate = false;
            }
            if (this.condition_is_square_feet == 1) {
              if (this.condition_square_feet_type <= 1 && !this.form.sqft) {
                this.validation.sqft = true;
                this.step1_validate = false;
              }

              if (this.condition_square_feet_type == 2) {
                if (!this.form.indoor_sqft) {
                  this.validation.indoor_sqft = true;
                  this.step1_validate = false;
                }

                if (!this.form.outdoor_sqft) {
                  this.validation.outdoor_sqft = true;
                  this.step1_validate = false;
                }
              }
            }

            if (this.is_multiple) {
              if (this.service_dates.length > this.until) {
                this.validation.date_msg = `${this.until} ကြိမ်သာ ရွေးရန်လိုအပ်သည်။`;
                this.validation.date = true;
                this.step1_validate = false;
              }
              if (this.service_dates.length < 1) {
                this.validation.date_msg = `အနည်းဆုံးတကြိမ် ရွေးရန်လိုအပ်သည်။`;
                this.validation.date = true;
                this.step1_validate = false;
              }
            }else {
              if (!this.form.date) {
                this.validation.date = true;
                this.step1_validate = false;
              }
            }

            //Additional Items
            if (this.is_additional_item) {
              let actual_item = this.additional_items;
              actual_item = Object.keys(actual_item).length;
              let current_item = this.form.additional_values;
              current_item = Object.keys(current_item).length;
              if (actual_item != current_item) {
                this.step1_validate = false;
                this.validation.additional_items = true;
              }
            }
          }

          if (this.step1_validate) {
            this.step++;
            this.form.survey_date = this.survey_date.selectedDateTime;
          }

        }else {
          this.step2_validate = true

          if (!this.form.phone_no) {
            this.validation.phone_no = true;
            this.step2_validate = false;
          }

          if (this.is_customer == 0 && !this.form.name) {
            this.validation.name = true;
            this.step2_validate = false;
          }
          if (this.is_customer == 1 && !this.form.address_id) {
            this.validation.address_id = true;
            this.step2_validate = false;
          }

          if (this.is_address == 1) {
            if (!this.form.address) {
              this.validation.address = true;
              this.step2_validate = false;
            }

            if (!this.form.township) {
              this.validation.township = true;
              this.step2_validate = false;
            }

            if (!this.form.bus_stop) {
              this.validation.bus_stop = true;
              this.step2_validate = false;
            }
          }

          if (this.step2_validate) {
            this.step++;
          }
        }
      },

      getStartTimes() {
          EventService.getStartTimes(this.id).then( data => {
            data = data['data']['data'];
            this.start_times = data;
            this.form.start_time = data[0].value;
          });
			},

      dateChange() {
        if (this.is_multiple) {
          this.service_dates = this.dates.selectedDates;
          if (this.service_dates.length > this.until) {
            this.validation.date_msg = `${this.until} ကြိမ်သာ ရွေးရန်လိုအပ်သည်။`;
            this.validation.date = true;
          }
          if (this.service_dates.length == this.until) {
            this.validation.date = false;
          }
        }else {
          if (this.dates.selectedDate) {
            this.form.date = this.dates.selectedDate;
            this.validation.date = false;
          }
        }
        this.getDurations();
        this.checkDate();
      },

      checkDate() {
        if (this.condition_is_urgent) {
          let date = this.is_multiple ? this.service_dates : this.form.date;
          EventService.checkDate(date, this.id).then(data => {
            data = data.data.data;
            this.form.is_urgent = data.is_urgent;
            this.start_times = data.times;
            this.form.start_time = this.start_times[0].value;
          });
        }
      },

      getSurveyDate()
      {
        if (this.survey_date.selectedDate) {
          let hour = this.survey_date.selectedHour;
          let fix = "AM";
          if (hour > 12) {
            fix = "PM";
            hour -= 12;
          }
          this.form.survey_time = `${hour}:${this.survey_date.selectedMinute} ${fix}`;
          this.form.survey_date_name = this.survey_date.selectedDate;
          this.form.survey_date = `${this.survey_date.selectedDate} ${this.form.survey_time}`;
          this.validation.survey_date = false;
        }
      },

      surveyDateChange() {
        this.getSurveyDate();
      },

      changeSurveyDate() {
        this.form.is_default_survey == 0 ? this.is_survey_date = 1 : this.is_survey_date = 0;
      },

      getDurations() {
          if (this.form.category_type == 1) {
            this.service_date = this.form.date ?? '';
          }
          EventService.getDurations(this.id, this.service_date, this.form.category_type, this.maid_qty).then( data => {
            data = data['data']['data']['data'];
            this.durations = data;
            this.form.duration = data[0].duration;
          });
      },

      getStart() {
        let query = this.$route.query;
        this.id = query['id'];
        this.form.scriber_id = query['user_id'];
        this.form.order_type = query['order_type'] ?? 0;
        this.until = query['until'] ?? 1;
        this.maid_qty = query['maid_qty'] ?? 1;
        this.showDate = true;
        if (this.form.order_type != 1) {
          this.form.category_type = query['category_type'];
          if (this.form.category_type == 2) {
            this.is_multiple = true;
            this.is_date = false;
          }
          this.showDate = false;
          this.getServiceDetail();   
          // this.dateLimitation();     
        } 
      },

      dateLimitation(type = "tomorrow")
      {
        let date = new Date();
        let tomorrow = new Date(date.setDate(date.getDate()+1));
        let three_month = new Date(date.setMonth(date.getMonth()+3));
        let today = new Date();
        today = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
        tomorrow = `${tomorrow.getDate()}/${tomorrow.getMonth() + 1}/${tomorrow.getFullYear()}`;
        three_month = `${three_month.getDate()}/${three_month.getMonth() + 1}/${three_month.getFullYear()}`;
        
        if (type == "tomorrow") {
          this.dateLimit = {min: tomorrow, max: three_month};
        }else {
          this.dateLimit = {min: today, max: three_month};
        }
      },

      getServiceDetail() {
        EventService.getServiceDetail(this.id).then(data => {
          data = data['data']['data'];
          this.condition_is_default_until = data.condition_is_default_until;
          this.condition_is_duration = data.condition_is_duration;
          this.condition_is_maid_quantity = data.condition_is_maid_quantity;
          this.condition_is_ot = data.condition_is_ot;
          this.condition_is_urgent = data.condition_is_urgent;
          this.condition_is_package = data.condition_is_package;
          this.condition_is_square_feet = data.condition_is_square_feet;
          this.condition_square_feet_type = data.condition_square_feet_type;
          this.condition_service_time_type = data.condition_service_time_type;
          this.condition_is_transportation_charge = data.condition_is_transportation_charge;
          this.condition_transportation_charge = data.condition_transportation_charge;
          this.dateLimitation(data.calendar_type);
          this.showDate = true;
        })
      },

      checkPhone() {
        let valid = this.form.phone_no ? false : true;
        this.validation.phone_no = valid;
        if (this.form.phone_no) {
          EventService.checkPhone(this.form.phone_no).then(data => {
            data = data['data']['data'];
            this.is_customer = data.is_customer;
            this.form.customer_id = data.customer_id;
            this.is_address = data.is_customer ? 0 : 1;
            this.form.name = data.name;
            if (this.is_customer == 1) {
              this.getCustomerAddresses();
            }else {
              this.form.name = '';
              this.form.address_id = '';
              this.form.address = '';
              this.form.township_name = '';
              this.form.bus_stop = '';
            }
          })
        }else {
          this.is_customer = 0;
          this.is_address = 1;
          this.form.name = '';
          this.form.address_id = '';
          this.form.address = '';
          this.form.township_name = '';
          this.form.bus_stop = '';
        }

      },

      getCustomerAddresses() {
        EventService.getCustomerAddresses(this.form.customer_id).then(data => {
          data = data['data']['data'];
          this.addresses = data;
          if (!this.form.address_id) {
            this.form.address_id = data[0] ? data[0].id : '';
            this.form.address = data[0] ? data[0].name : '';
            this.form.township_name = data[0] ? data[0].township : '';
          }
        })
      },

      customerAddress() {
        this.validation.address_id = false;
        this.is_address = 0;
        if (this.form.address_id == 0) {
          this.is_address = 1;
        }else {
          let find = this.addresses.find((ele) => ele.id == this.form.address_id);
          this.form.address = find.name;
          this.form.township_name = find.township;
        }
      },

      getTownships() {
        EventService.getTownships().then( data => {
          data = data['data']['data'];
          this.townships = data;
        });
			},

      getTownshipName() {
        let find = this.townships.find((ele) => ele.id == this.form.township);
        this.form.township_name = find.name;
      },

      getAdditionalItems() {
        EventService.getAdditionalItems(this.id, this.form.sqft, this.form.indoor_sqft, this.form.outdoor_sqft).then(data => {
            data = data['data'];
            this.is_additional_item = data.is_additional_items;
            this.additional_items = data['data'];

            let items = this.additional_result;
            this.additional_items.forEach((item, index) => {
              if(items[item.id]) {
                this.form.additional_values[item.id] = items[item.id];
              }
            });   
        });
      },

      getAddOnItems() {
        EventService.getAddOnItems(this.id, this.form.duration).then(data => {
          data = data['data'];
          this.is_addon_item = data.is_addon_item;
          this.addon_items = data.data;
        })
      },


      changeDuration() {
        this.validation.duration = false;
        this.getAddOnItems();
      },

      
      getFields() {
        EventService.getFields(this.form.scriber_id ?? '').then(data => {
          data = data['data'];
          let result = data['data'];
          let is_result = data['is_result'];
          if (is_result) {
            //Other Order
            if (this.form.order_type == 1) {
              if (result['survey_date']) {
                this.survey_date = result['survey_date'];  
                this.is_survey_date = 0;
                this.is_default_survey = 1;
                this.form.survey_date_name = this.survey_date.selectedDate;        
                if (result['survey_time']) {
                  let time = result['survey_time'];
                  this.form.survey_time = time;
                  this.form.survey_date = `${this.survey_date.selectedDate} ${time}`;
                }
              }
            }else {
              if (this.is_multiple) {
                if (result['service_dates']) {
                  this.dates = result['service_dates'];
                  this.service_dates = this.dates.selectedDates;
                }
              }else {
                if (result['service_dates']) {
                  this.dates = result['service_dates'];
                  this.form.date = this.dates.selectedDate;
                }
              }

              if (result['is_urgent']) {
                this.form.is_urgent = result['is_urgent'];                   
              }

              this.checkDate();

              if (result['start_time']) {
                this.form.start_time = result['start_time'];
              }

              if (result['duration']) {
                this.form.duration = result['duration'];
              }

              if (this.condition_is_square_feet === 1) {
                if (result['square_feet']) {
                  this.form.sqft = result['square_feet'];
                }

                if (result['indoor_square_feet']) {
                  this.form.indoor_sqft = result['indoor_square_feet'];
                }

                if (result['outdoor_square_feet']) {
                  this.form.outdoor_sqft = result['outdoor_square_feet'];
                }

                if (result['additional_items']) {
                  this.additional_result = result['additional_items'];                   
                }

                this.getAdditionalItems();
              }

              
              if (result['addon_items'] && this.is_addon_item) {
                this.form.addon_items = result['addon_items'];
              }
              
            }
            //Step2
            if (result['phone']) {
              this.form.phone_no = result['phone'];
            }

            if (result['customer_id']) {
              this.form.customer_id = result['customer_id'];
              this.is_customer = 1;
              this.getCustomerAddresses();
            }

            if (result['address_id']) {
              this.form.address_id = result['address_id'];
              this.is_address = 0;

            }

            if (result['name']) {
              this.form.name = result['name'];
            }

            if (result['customer_address']) {
              this.form.address = result['customer_address'];
            }

            if (result['township']) {
              this.form.address_id = 0;
              this.form.is_address = 1;
              this.form.township = result['township'];
            }

            if (result['bus_stop']) {
              this.form.bus_stop = result['bus_stop'];
            }

            if (result['remark']) {
              this.form.remark = result['remark'];
            }

          }
        })
      }
    },

    created() {  
      this.getStart();

      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'Messenger'));

    },

    beforeMount() {
			this.getTownships();
      if (this.form.order_type != 1) {
        this.getStartTimes();
        this.getDurations();
        this.getAdditionalItems();
        this.getAddOnItems();
      }
      this.getFields();
		},

  }
</script>