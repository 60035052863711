<template>
  <div class="home">
    <div class="container">
      <div class="row">
        <Home/>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Home from "@/components/Home.vue"

export default {
  components: {
    Home,
  },
};
</script>
